import request from '@/utils/request'
const BACK_API = '/back/api'
const BASE_PATH = `${BACK_API}/purchase`

const getOrders = (query) => {
  return request({
    url: `${BASE_PATH}/order/`,
    method: 'get',
    params: query
  })
}

export { 
  getOrders
}
